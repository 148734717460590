a{
   cursor: none;
}

a:hover{
   cursor: none;
}

#cardphoto1{
   background-position: center center;
   background-size: cover;
   background-repeat: no-repeat;
   border-radius: .75vw;
}

#cardphoto2{
   background-position: center center;
   background-size: cover;
   background-repeat: no-repeat;
   border-radius: .75vw;
}

.cardphoto1small{
   transform: scale(.8);
   opacity: 0;
}

.cardphoto1show{
   transform: scale(1);
   opacity: 1;
}

.cardphoto2big{
   transform: scale(1.2);
   opacity: 0;
}

.cardphoto2show{
   transform: scale(1);
   opacity: 1;
}

.cardpin{
   border-radius: 50%;
   border-width: 1.51vmax;
   height: 3vmax;
   width: 3vmax;
   background: #000000;
   border-color: white;
   transition: all 500ms ease-in-out;
}

#hiddencard{
   top: 0;
   left: 0
}

#back{
   border-radius: 50%;
}

body{
   overflow: hidden;
   background-color: #171717;
   font-family: 'Courier New', Courier, monospace;
   /*background-image: url(./files/Untitled-4.png);*/
   background-size: cover;
   /*cursor: url(./files/cursor.png), auto;*/
}

#canvas .cursor{
   position: absolute;
   border-radius: 50%;
   z-index: 1000;
   pointer-events: none;
}

#c1{
   background-color: white;
   height: 20px;
   width: 20px;
   transform: translate(0, 0);
}

#c2{
   background-color: #c5c5c5;
   height: 21px;
   width: 21px;
   transform: translate(0, calc(100vh - 100%));
}

#c3{
   background-color: rgb(80, 80, 80);
   height: 22px;
   width: 22px;
   transform: translate(calc(100vw - 100%), 0);
}

#c4{
   background-color: black;
   height: 23px;
   width: 23px;
   transform: translate(calc(100vw - 100%), calc(100vh - 100%));
}

.h-screen{
   max-height: -webkit-fill-available;
}

#canvas{
   overflow: hidden;
   position: fixed;
   height: 100vh;
   max-height: -webkit-fill-available;
   width: 100vw;
   /*cursor: url(./files/cursor.png), auto;*/
}

.imgcover{
   background-color: rgba(17, 17, 17, 0.75)
}

#card1{
   background: url(./files/card1.jpeg) center center / cover no-repeat;
}

#card2{
   background: url(./files/card2.png) center center / cover no-repeat;

}

#card3{
   background: url(./files/card3.png) 40% center / cover no-repeat;
}

.base{
   color: rgb(90, 90, 90);
   font-size: 10vmin;
   font-weight: 200;
   line-height: 7vmin;
   opacity: 0;
   transition: all 500ms ease-in-out;
}

.baseopen{
   transform: translate(0, 0);
   opacity: 0;
}

.headerbaseclosetop{
   transform: translate(0, 25vh);
   opacity: 1;
}

.middlebaseclosebottom{
   transform: translate(0, -50vh);
   opacity: 1;
}

.middlebaseclosetop{
   transform: translate(0, 50vh);
   opacity: 1;
}

.headerbaseclosebottom{
   transform: translate(0, -25vh);
   opacity: 1;
}

.headerbase{
   color: rgb(90, 90, 90);
   font-size: 4vmin;
   font-weight: 200;
   line-height: 3vmin;
   transition: all 500ms ease-in-out;
}

.namebase{
   color: white;
}

.clickbase{
   color: rgb(157, 157, 157)
}

.clickbase:hover .clickbase{
   color: rgb(200, 200, 200)
}

.unzippedup {
   animation: unzipup 0.5s ease-in-out forwards;
 }

 .unzippeddown {
   animation: unzipdown 0.5s ease-in-out forwards;
 }

 .zippedup {
   animation: zipup 0.5s ease-in-out reverse;
 }

 .zippeddown {
   animation: zipdown 0.5s ease-in-out reverse;
 }
 
 @keyframes unzipup {
   100% {
     transform: translateY(-50vh);
   }
 }

 @keyframes unzipdown {
   100% {
     transform: translateY(50vh);
   }
 }

 @keyframes zipup {
   100% {
     transform: translateY(50vh);
   }
 }

 @keyframes zipdown {
   100% {
     transform: translateY(-50vh);
   }
 }